.companyTitle {
    font-size: 1.3rem;
    margin: 0 0 0.75rem 0;
    line-height: 1.1;
}

.companyTitle i {
    color: red;
    font-size: 1.27rem;
    margin-right: 10px;
}

.companyName {
    display: inline-block;
}

.links {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.4;
    font-family: "Maisonneue Light";
    font-size: 0.95rem;
}

.header a {
    text-decoration: none;
}

.activeLink li {
    color: red;
}

.activeLink li::after {
    content: " ⇠";
}

activeLink li::hover::after {
    color: black;
    content: " ⇠";
}

.header {
    z-index: 2;
}

.subHeader {
    font-family: "Maisonneue Light";
    letter-spacing: 2.5px;
    font-size: 0.82rem;
    font-weight: 100;
}

@media screen and (min-width: 1024px) {
    .header {
        position: absolute;
        top: 55px;
        right: 50%;
        margin-right: 340px!important;
        letter-spacing: -.009em;
    }
}