.post {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 30px;
    padding-top: 5px;
    padding-bottom: 15px;
}

.post img {
    max-width: 80px;
}

.post strong {
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 0.92rem;
}