.map {
    height: 100vh;
    width: 100%;
    /* max-width: 640px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: -35px;
    /* position: absolute; */
    top: 0;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .map {
        margin-top: -106px;
    }
}