.project-box a.project {
    text-decoration: none;
}

.project-box a.project img {
    display: block;
    margin-bottom: 10px;
}

.project-description {
    /* font-family: "Maisonneue Light"; */
    /* font-family: "Crimson Text"; */
    /* font-family: "Rokkitt"; */
    font-family: "Amiri";
    /* font-family: "Karma"; */
    /* font-family: "Zilla Slab"; */
    line-height: 1;
    font-weight: 300;
    font-size: .95rem;
    color: #999;
}

.project-copy {
    line-height: 1.3;
    /* padding-top: 5px; */
}

.project {
    /* display: grid;
    grid-template-columns: min-content 1fr; */
    grid-gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: color 0.5s;
    color: #666;
}

.project img {
    max-width: 195px;
}

.project strong {
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 0.92rem;
}

.project:hover {
    color: red;
}

@media screen and (max-width: 600px) {
    .project{
        display: grid;
        grid-template-rows: min-content 1fr;
    }
}

@media screen and (min-width: 600px) {
    .project {
        display: grid;
        grid-template-columns: min-content 1fr;
    }
}