.limiter {
    max-width: 640px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

body {
    margin: 35px auto 0px auto;
    line-height: 1.6;
    font-size: 1rem;
    color: #111;
    font-family: 'Maisonneue Book';
    overflow-y: scroll;
}

img {
    /* width: 100%; */
    max-width: 640px;
}

a {
    outline:none;
}