@font-face {
    font-family: 'Maisonneue demi';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/59c53e234590f30001414ef4_MaisonNeue-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Maisonneue book';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/59c53e23b7ea8600018a5a40_MaisonNeue-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Maisonneue mono';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/59c53e23456750000195ac89_MaisonNeue-Mono.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Maisonneue Light';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/5a9da3f1da615c00014b3d1a_MaisonNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
/* @font-face {
    font-family: 'Crimson Text';
    src: url('https://fonts.googleapis.com/css?family=Crimson+Text');
    font-weight: 300;
    font-style: normal;
} */
/* @font-face {
    font-family: 'Amiri';
    src: url("https://fonts.googleapis.com/css?family=Amiri");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Rokkitt';
    src: url("https://fonts.googleapis.com/css?family=Rokkitt");
} */