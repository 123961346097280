body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000;
  text-decoration-skip-ink: auto;
}
a:visited {
  color: #333;
}
.companyTitle {
    font-size: 1.3rem;
    margin: 0 0 0.75rem 0;
    line-height: 1.1;
}

.companyTitle i {
    color: red;
    font-size: 1.27rem;
    margin-right: 10px;
}

.companyName {
    display: inline-block;
}

.links {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.4;
    font-family: "Maisonneue Light";
    font-size: 0.95rem;
}

.header a {
    text-decoration: none;
}

.activeLink li {
    color: red;
}

.activeLink li::after {
    content: " \21E0";
}

activeLink li::hover::after {
    color: black;
    content: " \21E0";
}

.header {
    z-index: 2;
}

.subHeader {
    font-family: "Maisonneue Light";
    letter-spacing: 2.5px;
    font-size: 0.82rem;
    font-weight: 100;
}

@media screen and (min-width: 1024px) {
    .header {
        position: absolute;
        top: 55px;
        right: 50%;
        margin-right: 340px!important;
        letter-spacing: -.009em;
    }
}
.map {
    height: 100vh;
    width: 100%;
    /* max-width: 640px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: -35px;
    /* position: absolute; */
    top: 0;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .map {
        margin-top: -106px;
    }
}
.project-box a.project {
    text-decoration: none;
}

.project-box a.project img {
    display: block;
    margin-bottom: 10px;
}

.project-description {
    /* font-family: "Maisonneue Light"; */
    /* font-family: "Crimson Text"; */
    /* font-family: "Rokkitt"; */
    font-family: "Amiri";
    /* font-family: "Karma"; */
    /* font-family: "Zilla Slab"; */
    line-height: 1;
    font-weight: 300;
    font-size: .95rem;
    color: #999;
}

.project-copy {
    line-height: 1.3;
    /* padding-top: 5px; */
}

.project {
    /* display: grid;
    grid-template-columns: min-content 1fr; */
    grid-gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: color 0.5s;
    color: #666;
}

.project img {
    max-width: 195px;
}

.project strong {
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 0.92rem;
}

.project:hover {
    color: red;
}

@media screen and (max-width: 600px) {
    .project{
        display: grid;
        grid-template-rows: -webkit-min-content 1fr;
        grid-template-rows: min-content 1fr;
    }
}

@media screen and (min-width: 600px) {
    .project {
        display: grid;
        grid-template-columns: -webkit-min-content 1fr;
        grid-template-columns: min-content 1fr;
    }
}
.contentWrapper {
    padding-top: 15px;
    padding-bottom: 20px;
}

.project-seperator {
    border-top: #bbb 1px dashed;
    border-bottom: #bbb 1px dashed;
}
.post {
    display: grid;
    grid-template-columns: -webkit-min-content 1fr;
    grid-template-columns: min-content 1fr;
    grid-gap: 30px;
    padding-top: 5px;
    padding-bottom: 15px;
}

.post img {
    max-width: 80px;
}

.post strong {
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 0.92rem;
}
.project-box a.post {
    text-decoration: none;
    color: #666;
}

.project-box a.post img {
    display: block;
    margin-bottom: 10px;
}

.post-description {
    /* font-family: "Maisonneue Light"; */
    /* font-family: "Crimson Text"; */
    /* font-family: "Rokkitt"; */
    font-family: "Amiri";
    /* font-family: "Karma"; */
    /* font-family: "Zilla Slab"; */
    line-height: 1;
    font-weight: 300;
    font-size: .95rem;
    color: #999;
}

.post-copy {
    line-height: 1.3;
    /* padding-top: 5px; */
}
.limiter {
    max-width: 640px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

body {
    margin: 35px auto 0px auto;
    line-height: 1.6;
    font-size: 1rem;
    color: #111;
    font-family: 'Maisonneue Book';
    overflow-y: scroll;
}

img {
    /* width: 100%; */
    max-width: 640px;
}

a {
    outline:none;
}
@font-face {
    font-family: 'Maisonneue demi';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/59c53e234590f30001414ef4_MaisonNeue-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Maisonneue book';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/59c53e23b7ea8600018a5a40_MaisonNeue-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Maisonneue mono';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/59c53e23456750000195ac89_MaisonNeue-Mono.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Maisonneue Light';
    src: url('https://uploads-ssl.webflow.com/59c3a820387ac60001b24d4d/5a9da3f1da615c00014b3d1a_MaisonNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
/* @font-face {
    font-family: 'Crimson Text';
    src: url('https://fonts.googleapis.com/css?family=Crimson+Text');
    font-weight: 300;
    font-style: normal;
} */
/* @font-face {
    font-family: 'Amiri';
    src: url("https://fonts.googleapis.com/css?family=Amiri");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Rokkitt';
    src: url("https://fonts.googleapis.com/css?family=Rokkitt");
} */
