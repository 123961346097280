.project-box a.post {
    text-decoration: none;
    color: #666;
}

.project-box a.post img {
    display: block;
    margin-bottom: 10px;
}

.post-description {
    /* font-family: "Maisonneue Light"; */
    /* font-family: "Crimson Text"; */
    /* font-family: "Rokkitt"; */
    font-family: "Amiri";
    /* font-family: "Karma"; */
    /* font-family: "Zilla Slab"; */
    line-height: 1;
    font-weight: 300;
    font-size: .95rem;
    color: #999;
}

.post-copy {
    line-height: 1.3;
    /* padding-top: 5px; */
}